/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { Box, Flex } from 'rebass'
import { get, lowerCase, truncate } from 'lodash';

import { Container, Layout, Section } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { SEO } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'
import { PubHero, Pill } from '../components/elements'


const project = (props) => {

    //const page = data.prismic.project
    const edges = get(props.data, 'prismic.allProjects.edges', []);
    const data = get(edges, '0.node');

    if (!data) {
        return null;
    }

    return (
        <Layout>
            <SEO meta_title={RichText.asText(data.title)} meta_description={`${truncate(RichText.asText(data.content), {
                length: 140,
                separator: ' ',
            }
            )}`} />

            <PubHero title="Projects" />
            <Section >
                <Container>
                    <Flex flexWrap="wrap" justifyContent="center">
                        <Box width={['full', 4 / 5, 3 / 4]} sx={{
                            backgroundColor: 'wrapperbox',
                            padding: [4, 5],
                        }}>
                            <Flex flexWrap="wrap" justifyContent="space-between" alignItems="center" mb={2}>
                                <h2>{RichText.asText(data.title)}</h2>
                                <Pill className={`${lowerCase(data.project_type)}`}>{data.project_satus}</Pill>
                            </Flex>
                            <hr />
                            {RichText.render(data.content)}
                        </Box>
                    </Flex>
                </Container>
            </Section>
        </Layout>
    )
}

export default project

export const query = graphql`
query ProjectQuery($uid: String!) {
  prismic {
    allProjects(lang: "en-us", uid: $uid) {
        edges{
            node {
                _meta {
                    uid
                }
                title
                content
                project_type
                project_satus
            }
        }
    }
  }
}
`
